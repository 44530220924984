import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { getPolicyDetails, getSinglePolicyDetails } from '../services';
import { notify } from '../components/actions/CommonActions';
import moment from 'moment';
import { Dropdown } from "react-bootstrap"; 
import { Link, useParams } from 'react-router-dom';
import { MdKeyboardArrowLeft } from 'react-icons/md';

const PolicyDetails = () => {

    
  const [totalPages, setTotalPages] = useState(1);
  const [numInput, setNumInput] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [gottenPolicy, setPolicyGotten] = useState(null)
  const [error, setError] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [showChange, setShowChange] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [mandates, setMandates] = useState();
  const [polDetails, setPolDetails] = useState(null)

  const {id} = useParams();
//   console.log("id of policy ", id);


  

  const toggleShowChange = () => {
    setShowChange(!showChange);
  };

  useEffect(() => {
    const initiate = async () => {
      setIsLoading(true)
        await getSinglePolicyDetails(id, numInput, 1)
      .then((res)=>{
        // console.log('res ',res);
        setPolicyGotten(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      await getPolicyDetails(id)
      .then((res)=>{
        // console.log('res ',res?.data);
        setPolDetails(res?.data);
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsLoading(false)
    }
    initiate()
}, []);

  const handlePageClick = async(data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage)
    setIsLoading(true)
    await getSinglePolicyDetails(id, numInput, selectedPage)
      .then((res)=>{
        // console.log('res ',res);
        setPolicyGotten(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsLoading(false)
  };

  const handleSetPageClick = async(data) => {
    console.log(numInput, currentPage);
    setIsLoading(true)
    await getSinglePolicyDetails(id, numInput, currentPage)
      .then((res)=>{
        // console.log('res ',res);
        setPolicyGotten(res);
        setTotalPages(Number(res?.data?.totalCount)/Number(numInput))
      })
      .catch((error)=>{
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ',error);
      })
      setIsLoading(false)
  };

  // console.log('policy gotten ', gottenPolicy);
  
  
  const dataToRender = filteredData.length > 0 ? filteredData : mandates;


  return (
    <>
    {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, minHeight: '100vh' }}>
          <div className="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
    <div>
      <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
        <Link to="/upload-file" style={{border: "none", padding: "5px 10px", borderRadius: "5px", backgroundColor: "#007dcc", color: "white", fontWeight: "bold",}}><MdKeyboardArrowLeft /> Back</Link>
      </div>

      <div style={{borderRadius: "10px", background: "#e9ecef", padding: "10px", marginTop: "20px", marginBottom: "20px"}}>
        <div style={{display: "flex", marginTop: "5px"}}>
          <p style={{fontSize: "16px", fontWeight: 600, marginBottom: "0px"}}>Policy Number:</p>
          <p style={{fontSize: "16px", marginBottom: "0px", marginLeft: "40px"}}>{polDetails?.policyNumber}</p>
        </div>
        <div style={{display: "flex", marginTop: "5px"}}>
          <p style={{fontSize: "16px", fontWeight: 600, marginBottom: "0px"}}>Naicom ID:</p>
          <p style={{fontSize: "16px", marginBottom: "0px", marginLeft: "40px"}}>{polDetails?.naicomPolicyID}</p>
        </div>
        <div style={{display: "flex", marginTop: "5px"}}>
          <p style={{fontSize: "16px", fontWeight: 600, marginBottom: "0px"}}>Insured:</p>
          <p style={{fontSize: "16px", marginBottom: "0px", marginLeft: "40px"}}>{polDetails?.insured}</p>
        </div>
        <div style={{display: "flex", marginTop: "5px"}}>
          <p style={{fontSize: "16px", fontWeight: 600, marginBottom: "0px"}}>Members:</p>
          <p style={{fontSize: "16px", marginBottom: "0px", marginLeft: "40px"}}>{polDetails?.members}</p>
        </div>
      </div>
        <div class="table-wrapper">
      <table className="fl-table">
        <thead>
          <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
            {/* Table header cells */}
            {/* Modify according to your data structure */}
            <th width="11%">Name</th>
            <th>Insured number</th>
            <th>Date of birth</th>
            <th>Gender</th>
            <th>Phone number</th>
            <th>Created date</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <>
              <td className="gray-td">Loading, please wait...</td>
            </>
          ) : error ? (
            <>
              <td className="gray-td">Error: {error}</td>
            </>
          ) : dataToRender?.length === 0 ? (
            <>
              <td className="gray-td">No Information uploaded.</td>
            </>
          ) : (
            gottenPolicy?.data?.members?.map((mandate) => (
              <tr
                className="tr"
                key={mandate.id}
              >
                <td className="gray-td">{mandate?.name}</td>
                <td className="gray-td">{mandate?.insuredNumber}</td>
                <td className="gray-td">{mandate?.dob}</td>
                <td className="gray-td">{mandate?.gender}</td>
                <td className="gray-td">
                  {mandate?.phoneNumber}
                </td>
                <td className="gray-td">
                  {moment(mandate?.createdAt).format('YYYY-MM-DD')}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      </div>
      <div className="pagination-container">
        {/* Pagination controls */}
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={numInput}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName="active-page"
          breakLabel="..."
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
        <input
          type="number"
          value={numInput}
          onChange={(e) => {
            setNumInput(parseInt(e.target.value));
            sessionStorage.setItem("pageNum", e.target.value);
          }}
        />
        <button
          style={{
            backgroundColor: "transparent",
            border: "0.5px solid #000",
            color: "#fff",
            backgroundColor: "#007dcc",
            padding: "4px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            e.preventDefault()
            handleSetPageClick()
          }}
        >
          set
        </button>
      </div>
    </div>
      )}
    </>
  )
}

export default PolicyDetails