import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const LoadingModal = ({ show }) => {
  return (
    <Modal show={show} centered backdrop="static" keyboard={false}>
      <Modal.Body className="text-center py-4">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="mt-3">Please wait...</p>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
