import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FiDownload, FiFilter, FiRefreshCcw } from "react-icons/fi";
import { GoDot, GoDotFill, GoFilter } from "react-icons/go";
import { FilterModal } from "../components/modals/FilterModal";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { notify } from "../components/actions/CommonActions";
import { IoCloseCircle, IoDocumentTextOutline } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
import * as XLSX from "xlsx";
import { IoMdDownload } from "react-icons/io";
import { getPolicy, getUploadErrorNaicom, getUploadErrorNiid, uploadMember, uploadPolicy } from "../services";
import { saveAs } from 'file-saver';
import file from '../assets/group-life-policy-template.xlsx';
import file2 from "../assets/grouplife-members-template.xlsx"
import moment from "moment";
import '../App.css'
import { UpdatePolicyModal } from "../components/modals/UpdatePolicyModal";
import { HiOutlineDotsVertical } from "react-icons/hi";
import LoadingModal from "../components/modals/LoadingModal";
import ReasonModal from "../components/modals/ReasonModal";

export const UploadedFiles = () => {
  const [filter, setFilter] = useState({
    mandateCode: "",
    subscriberCode: "",
    approverStatus: "",
    startDate: "",
    bank: "",
    dateApproved: "",
    amount: "",
    nextDebitDate: "",
    payer: "",
  });

  const [showFilter, setShowFilter] = useState(false);
  const [mandates, setMandates] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [numInput, setNumInput] = useState(10);

  //   const { user, banks } = useContext(AuthContext);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isUploadMemberFile, setIsUploadMemberFile] = useState(false);
  const [batchFile, setBatchFile] = useState(null);
  const [batchMemberFile, setBatchMemberFile] = useState(null);
  const [errorFile, setErrorFile] = useState('');
  const [gottenPolicy, setPolicyGotten] = useState(null)
  const [showChange, setShowChange] = useState(false); const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const [reason, setReason] = useState(null)
  const [loadingReason, setLoadingReason] = useState(false)
  const [reasonModal, setReasonModal] = useState(false)

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validateDateFormat = (date) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(date);
  };

  //New pagination logic test //

  const fetchMandates = async (page) => {
    setIsLoading(true);

  };



  useEffect(() => {
    const getRolesApi = async () => {
      setIsLoading(true)
      await getPolicy(numInput, 1)
        .then((res) => {
          setPolicyGotten(res?.data)
          setTotalPages(Number(res?.data?.totalCount) / Number(numInput))
        })
        .catch((error) => {
          notify("danger", "OOPS", error?.response?.data?.message);
          console.log('error ', error);
        })
      setIsLoading(false)
    }
    getRolesApi()
  }, [])

  const handleRefetch = async () => {
    setIsLoading(true)
    await getPolicy(numInput, 1)
      .then((res) => {
        setPolicyGotten(res?.data)
        setTotalPages(Number(res?.data?.totalCount) / Number(numInput))
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ', error);
      })
    setIsLoading(false)
  }

  const handleReasonNiid = async (id) => {
    setLoadingReason(true)
    await getUploadErrorNiid(id)
      .then((res) => {
        // console.log("reason info niid ", res?.data);
        setReason(res?.data)
        setReasonModal(true)
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        // console.log('error ', error);
      })
      setLoadingReason(false)
  }

  const handleReasonNaicom = async (id) => {
    setLoadingReason(true)
    await getUploadErrorNaicom(id)
      .then((res) => {
        // console.log("reason info naicom ", res?.data);
        setReason(res?.data)
        setReasonModal(true)
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        // console.log('error ', error);
      })
      setLoadingReason(false)
  }

  const handleSetPageClick = async (data) => {
    console.log(numInput, currentPage);
    setIsLoading(true)
    await getPolicy(numInput, currentPage)
      .then((res) => {
        // console.log('res ',res);
        setPolicyGotten(res?.data);
        setTotalPages(Number(res?.data?.totalCount) / Number(numInput))
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ', error);
      })
    setIsLoading(false)
  };

  const handlePageClick = async (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage)
    setIsLoading(true)
    await getPolicy(numInput, selectedPage)
      .then((res) => {
        // console.log('res ',res);
        setPolicyGotten(res?.data);
        setTotalPages(Number(res?.data?.totalCount) / Number(numInput))
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log('error ', error);
      })
    setIsLoading(false)
  };

  //New pagination logic test //

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  // console.log(banks);

  const handleChange = (e) => {
    const name = e.target.name;
    let value;
    switch (name) {
      case "frequency":
        value = parseInt(e.target.value);

        break;

      case "approverStatus":
        value = parseInt(e.target.value);

        break;

      default:
        value = e.target.value;
        break;
    }
    // const value = e.target.value;
    setFilter({ ...filter, [name]: value });
  };

  const frequencies = [
    { code: 1, name: "Monthly" },
    { code: 3, name: "Quarterly" },
    { code: 6, name: "Half-yearly" },
    { code: 12, name: "Yearly" },
    { code: 99, name: "Daily" },
  ];


  const handleFileChange = (e) => {
    setErrorFile("")
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      for (let row of json) {
        if (
          (row['Commencement Date'] && !validateDateFormat(row['Commencement Date'])) ||
          (row['Expiry Date'] && !validateDateFormat(row['Expiry Date']))
        ) {
          setErrorFile(`Invalid date format in row: ${JSON.stringify(row)}. Date format should be DD/MM/YYYY`);
          return;
        }
      }

      setError(''); // Clear error if all dates are valid
      // Process data if needed
    };
    reader.readAsArrayBuffer(file);
    // console.log('file uploaded ',file);
    setBatchFile(file)

  };

  const handleFileMemberChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      for (let row of json) {
        if (
          (row['Commencement Date'] && !validateDateFormat(row['Commencement Date'])) ||
          (row['Expiry Date'] && !validateDateFormat(row['Expiry Date']))
        ) {
          setErrorFile(`Invalid date format in row: ${JSON.stringify(row)}. Date format should be DD/MM/YYYY (text format)`);
          return;
        }
      }

      setError(''); // Clear error if all dates are valid
      // Process data if needed
    };
    reader.readAsArrayBuffer(file);
    // console.log('file uploaded ',file);
    setBatchMemberFile(file)

  };

  const dataLoadingStatus = [
    {
      id: 1,
      status: true,
    },
    {
      id: 0,
      status: false,
    },
  ];

  const handleSearch = async () => {
  };

  const handleUploadFile = async () => {
    setIsUploadFile(true);
    const formData = new FormData()
    formData.append('document', batchFile)
    await uploadPolicy(formData)
      .then((res) => {
        notify("success", "success", res?.message);
        setIsUploadFile(false);
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsUploadFile(false);
      });
  };

  const handleUploadMemberFile = async () => {
    setIsUploadMemberFile(true);
    const formData = new FormData()
    formData.append('document', batchMemberFile)
    await uploadMember(formData)
      .then((res) => {
        notify("success", "success", res?.message);
        setIsUploadMemberFile(false);
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsUploadMemberFile(false);
      });
  };

  const handleDownloadPolicyTemplate = async () => {
    const token = sessionStorage.getItem("token") || null;
    setIsTemplateLoading(true);
    fetch(file)
      .then(response => response.blob())
      .then(blob => {
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'group-life-policy-template.xlsx'; // Specify the filename

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        // Revoke the object URL after download
        URL.revokeObjectURL(link.href);
      })
      .catch(err => console.error('Download failed:', err));
    setIsTemplateLoading(false);
  };

  const handleDownloadMemberTemplate = async () => {
    const token = sessionStorage.getItem("token") || null;
    setIsTemplateLoading(true);
    fetch(file2)
      .then(response => response.blob())
      .then(blob => {
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'grouplife-members-template.xlsx'; // Specify the filename

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        // Revoke the object URL after download
        URL.revokeObjectURL(link.href);
      })
      .catch(err => console.error('Download failed:', err));
    setIsTemplateLoading(false);
  };

  const toggleShowChange = () => {
    console.log("works");

    setShowChange(!showChange);
  };

  const dataToRender = filteredData.length > 0 ? filteredData : mandates;

  const navigate = useNavigate();
  return (
    <>
      <Row className="mb-3 mt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: "45%" }}>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Upload Policy File</Form.Label>
            <InputGroup>
              <Form.Control
                type="file"
                placeholder="Upload excel file."
                name="excelFile"
                onChange={handleFileChange}
              />
              <Button
                variant="outline-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  handleUploadFile();
                }}
                disabled={isUploadFile}
              >
                {isUploadFile ? <FiRefreshCcw className="spin" /> : "Upload"}
              </Button>
            </InputGroup>
            {errorFile && <div style={{ color: 'red' }}>{errorFile}</div>}
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={(e) => {
              e.preventDefault();
              handleDownloadPolicyTemplate()
            }} style={{ marginTop: "10px", backgroundColor: "#007dcc" }}>
              {isTemplateLoading ? "Downloading..." : "Download Policy Template"}{" "}
              <IoMdDownload />
            </Button>
            <div></div>
          </div>
        </div>

        <div style={{ width: "45%" }}>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Upload Member File</Form.Label>
            <InputGroup>
              <Form.Control
                type="file"
                placeholder="Upload excel file."
                name="excelFile"
                onChange={handleFileMemberChange}
              />
              <Button
                variant="outline-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  handleUploadMemberFile();
                }}
                disabled={isUploadFile}
              >
                {isUploadMemberFile ? <FiRefreshCcw className="spin" /> : "Upload"}
              </Button>
            </InputGroup>
            {/* {errorFile && <div style={{ color: 'red' }}>{errorFile}</div>} */}
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={(e) => {
              e.preventDefault();
              handleDownloadMemberTemplate()
            }} style={{ marginTop: "10px", backgroundColor: "#007dcc" }}>
              {isTemplateLoading ? "Downloading..." : "Download Member Template"}{" "}
              <IoMdDownload />
            </Button>
            <div></div>
          </div>
        </div>
      </Row>

      <div
        style={{
          width: "100%",
          paddingBottom: 5,
          borderBottom: "1px solid #EAECF0",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <h5 style={{ textTransform: "capitalize", fontWeight: 700 }}>
          All Uploaded Files
        </h5>
      </div>

      <div className="mt-4 d-flex align-items-center justify-content-between">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {/* <button className="light-button" onClick={toggleFilter}>
            {" "}
            <GoFilter /> Filter
          </button> */}
          {isFilterActive && (
            <button
              className="light-button"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setIsFilterActive(false);
                sessionStorage.removeItem("filteredValues");
                sessionStorage.removeItem("isFilterActiveButton");
                fetchMandates(1);
                window.location.reload();
              }}
            >
              {" "}
              <IoCloseCircle /> Clear filters
            </button>
          )}
        </div>

      </div>

      <div class="table-wrapper">
        <table className="fl-table">
          <thead>
            <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
              {/* Table header cells */}
              {/* Modify according to your data structure */}
              <th width="11%">Policy No</th>
              <th>Office Address</th>
              <th>Insured</th>
              <th>No of Employees</th>
              <th>Customer Mobile</th>
              <th> NAICOM Unique ID</th>
              <th>Commencement Date</th>
              <th>Expiry Date</th>
              <th>Uploaded to NIID</th>
              <th>Uploaded to NAICOM</th>
              <th>...</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <>
                <td className="gray-td">Loading, please wait...</td>
              </>
            ) : error ? (
              <>
                <td className="gray-td">Error: {error}</td>
              </>
            ) : dataToRender?.length === 0 ? (
              <>
                <td className="gray-td">No Information uploaded.</td>
              </>
            ) : (
              gottenPolicy?.policies?.map((mandate) => (
                <tr
                  className="tr"
                  key={mandate.id}
                >
                  <td className="gray-td">{mandate?.policyNumber}</td>
                  <td className="gray-td">{mandate?.officeAddress}</td>
                  <td className="gray-td">{mandate?.insured}</td>
                  <td className="gray-td">{mandate?.noOfEmployees}</td>
                  <td className="gray-td">
                    {mandate?.mobileNumber}
                  </td>
                  <td className="gray-td">
                    {mandate?.naicomUniquePolicyId}
                  </td>
                  <td className="gray-td">
                    {moment(mandate?.commencementDate).format('YYYY-MM-DD')}
                  </td>
                  <td className="gray-td">
                    {moment(mandate?.expiryDate).format('YYYY-MM-DD')}
                  </td>
                  <td className="gray-td" style={{ color: mandate?.uploadedToNiid ? "green" : "red" }}>
                    {/* {mandate?.uploadedToNiid ? "Yes" : "No"} */}
                    {mandate?.uploadedToNiid ? 
                    <span style={{ backgroundColor: "green", borderRadius: "10px", padding: "5px 8px", color: "white", marginRight: "20px" }}>Yes</span> 
                    : 
                    <span style={{ backgroundColor: "red", borderRadius: "10px", padding: "5px 8px", color: "white", marginRight: "20px"  }}>No</span>
                    } 
                    {mandate?.errored && <span onClick={()=> handleReasonNiid(mandate?.policyNumber)} style={{ backgroundColor: "#0d6efd", borderRadius: "10px", padding: "5px 8px", color: "white", cursor: "pointer" }}>Reason</span>}
                  </td>
                  <td className="gray-td" style={{ color: mandate?.uploadedToNiid ? "green" : "red" }}>
                    {mandate?.uploadedToNaicom ? 
                    <span style={{ backgroundColor: "green", borderRadius: "10px", padding: "5px 8px", color: "white", marginRight: "20px"  }}>Yes</span> 
                    : 
                    <span style={{ backgroundColor: "red", borderRadius: "10px", padding: "5px 8px", color: "white", marginRight: "20px"  }}>No</span>
                    } 
                    {mandate?.erroredAtNaicom && <span onClick={()=> handleReasonNaicom(mandate?.policyNumber)} style={{ backgroundColor: "#0d6efd", borderRadius: "10px", padding: "5px 8px", color: "white", cursor: "pointer" }}>Reason</span>}
                  </td>
                  <td>
                    {/* <Dropdown>
                            <Dropdown.Toggle
                              style={{
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                backgroundColor: "transparent",
                                color: "#000",
                                border: "none",
                                padding: 0,
                              }}
                              id="dropdown-basic"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu style={{ fontSize: "0.7rem", zIndex: 1050, position: "relative"  }} container="body">
                              
                              <Dropdown.Item
                                onClick={() => {
                                  toggleShowChange();
                                  setSelectedPolicy(mandate)
                                }}
                              >
                                Update policy
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown> */}
                    <div className="relative" style={{ position: "relative" }}>
                      {/* Dropdown Toggle Button */}
                      <button
                        onClick={() => toggleDropdown(mandate.id)}
                        className="dotsBtn"
                      >
                        <HiOutlineDotsVertical />
                      </button>

                      {/* Dropdown Menu - Show only for the selected row */}
                      {openDropdownId === mandate.id && (
                        <div className="dropdownSelect" ref={dropdownRef}>
                          <div className="updateBtn">
                            <button
                              onClick={() => {
                                navigate(`/policy-details/${mandate.policyNumber}`)
                              }}
                              style={{ border: "none", backgroundColor: "white", padding: "5px" }}
                            >
                              View policy
                            </button>
                            <button
                              onClick={() => {
                                toggleShowChange();
                                setSelectedPolicy(mandate);
                                setOpenDropdownId(null);
                              }}
                              style={{ border: "none", backgroundColor: "white", padding: "5px" }}
                            >
                              Update Policy
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        {/* Pagination controls */}
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={numInput}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName="active-page"
          breakLabel="..."
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
        <input
          type="number"
          value={numInput}
          onChange={(e) => {
            setNumInput(parseInt(e.target.value));
            sessionStorage.setItem("pageNum", e.target.value);
          }}
        />
        <button
          style={{
            backgroundColor: "transparent",
            border: "0.5px solid #000",
            color: "#fff",
            backgroundColor: "#007dcc",
            padding: "4px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            e.preventDefault()
            handleSetPageClick()
          }}
        >
          set
        </button>
      </div>

      <FilterModal
        toggleFilter={toggleFilter}
        showFilter={showFilter}
        handleChange={handleChange}
        handleSearch={handleSearch}
        filterLoading={filterLoading}
        dataLoadingStatus={dataLoadingStatus}
        isFilterActive={isFilterActive}
        setIsFilterActive={setIsFilterActive}
      />

      <UpdatePolicyModal showUser={showChange} toggleShowUser={toggleShowChange} refetch={handleRefetch}
        selectedPolicy={selectedPolicy} />

        <LoadingModal show={loadingReason} />

        <ReasonModal show={reasonModal} onHide={()=> setReasonModal(false)} description={reason?.reason} />
    </>
  );
};
